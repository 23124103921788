import { useRef } from "react";

const Overview = ({ articleData }) => {
  let headerIndex = 0;

  const h2Positions = useRef([]);
  const handleWindowLoad = () => {
    const h2Elements = document.querySelectorAll(".information__header--post");

    h2Positions.current = Array.from(h2Elements).map((h2) => ({
      position: h2.offsetTop,
    }));

    const h2PositionsArray = h2Positions.current.map((item) => item.position);
    if (h2PositionsArray.length > 0) {
      return h2PositionsArray;
    }
  };

  const handleClickScroll = (event) => {
    const headerTopPosition = handleWindowLoad();
    let scrollHeight = 0;
    if (event.target.getAttribute("index") !== null) {
      let clickedHeadline = event.target.getAttribute("headerindex") - 1;
      if (clickedHeadline === -1) {
        clickedHeadline = 0;
      }

      scrollHeight = headerTopPosition[clickedHeadline];
    }

    window.scrollTo({
      top: scrollHeight,
      left: 0,
      behavior: "smooth",
    });
  };
  if (articleData) {
    return (
      <aside className="content__overview">
        {articleData.map((element, index) => {
          if (element.tag === "h1") {
            return (
              <h3
                key={index}
                onClick={handleClickScroll}
                className="content__overviewTitle"
              >
                {element.text}
              </h3>
            );
          }
          return null;
        })}

        <ul>
          {articleData.map((element, index) => {
            const headlineTags = ["h2", "h3", "h4"];

            if (headlineTags.includes(element.tag) && index !== 0) {
              if (
                element.tag === "h4" &&
                element.className === "sponsored_headline"
              ) {
                return null;
              }
              //* h5 added only for blic sportal
              headerIndex++;
              return (
                <li key={index} className="content__overviewHeadline">
                  <button
                    headerindex={headerIndex}
                    index={index}
                    onClick={handleClickScroll}
                    className="content__overviewLink"
                  >
                    {element.text}
                  </button>
                </li>
              );
            }
            return null;
          })}
        </ul>
      </aside>
    );
  } else {
    return <aside className="content__overview"></aside>;
  }
};

export default Overview;
