const DonationSidebar = ({ mainPageData }) => {
  if (mainPageData) {
    const sortedData = mainPageData
      .filter((data) => data.position === "side")
      .sort((a, b) => a.order - b.order);
    return (
      <aside className="content__donationBox">
        {sortedData.map((block, index) => (
          <div key={index}>
            <h3 className="content__donationTitle">{block["headline"]}</h3>
            <div dangerouslySetInnerHTML={{ __html: block["description"] }} />
          </div>
        ))}
      </aside>
    );
  }
  return null;
};

export default DonationSidebar;
