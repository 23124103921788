import React from "react";
import DonationButton from "./donationButton";

const ArticleContent = ({ articleData, featuredImageURL }) => {
  let featureName;
  let listItems;
  const isFeatureName = articleData.find(
    (element) => element.tag === "feature_name"
  );

  if (isFeatureName) {
    featureName = isFeatureName.text;
  }
  if (featureName === "list") {
    listItems = articleData.filter((element) => element.tag === "li");
  }

  const splitList = (element, index) => {
    if (element.tag === "ul") {
      const listItems = [];
      let i = index + 1;

      while (i < articleData.length && articleData[i].tag === "li") {
        listItems.push(<li key={i}>{articleData[i].text}</li>);
        i++;
      }

      return listItems;
    }
  };

  function createSourceLink(inputString) {
    const [name, url] = inputString.split(">");

    return (
      <span className="photo_details">
        <a
          className="photo_link"
          href={url + "?utm_source=sacuvaj&utm_medium=referral"}
          target="_blank"
          rel="noopener noreferrer"
        >
          {name}
        </a>{" "}
        <a
          href="https://unsplash.com/?utm_source=sacuvaj&utm_medium=referral"
          className="photo_link"
          target="_blank"
          rel="noopener noreferrer"
        >
          (Unsplash)
        </a>
      </span>
    );
  }

  // Filter elements with the desired tags
  const relevantElements = articleData.filter((element) =>
    ["p", "h3", "img"].includes(element.tag)
  );

  // Calculate the midpoint based on relevant elements
  const midpointIndex = Math.floor(relevantElements.length / 2);

  // Get the original index of the midpoint element in articleData
  const midpointOriginalIndex = articleData.findIndex(
    (el, idx) =>
      el === relevantElements[midpointIndex] && articleData.indexOf(el) === idx
  );

  const sponsorBox = (
    <React.Fragment key="sponsored">
      <section className="information__paragraph--sponsored">
        <h4 className="sponsored_headline">Podržite naš servis!</h4>
        <p>
          Vaša podrška nam puno znači! Svaka donacija, ma koliko mala, pomaže da
          ovaj servis raste, opstane i postane još bolji za vas. Podržite
          Sačuvaj ukoliko ste u mogućnosti i omogućite mu da nastavi sa radom.
        </p>
        <DonationButton article={true} />
      </section>
    </React.Fragment>
  );

  if (!featureName) {
    return articleData.flatMap((element, index) => {
      const isMidpoint = index === midpointOriginalIndex;

      // Sponsored content to be inserted at the calculated position
      const sponsoredContent = isMidpoint ? sponsorBox : null;

      // Render the current element

      const renderedElement = (
        <React.Fragment key={index}>
          {element.tag === "p" || element.tag === "h5" ? (
            <p className="information__paragraph">{element.text}</p>
          ) : null}
          {element.tag === "h2" ? (
            <h2 className="information__header information__header--post">
              {element.text}
            </h2>
          ) : null}
          {element.tag === "h3" ? (
            <h3 className="information__header information__header--post">
              {element.text}
            </h3>
          ) : null}
          {element.tag === "h4" ? (
            <h3 className="information__header information__header--post">
              {element.text}
            </h3>
          ) : null}
          {element.tag === "ul" ? (
            <ul className="information__paragraph">
              {splitList(element, index)}
            </ul>
          ) : null}
          {element.tag === "img" && element.text !== featuredImageURL ? (
            <div className="image_container">
              <img alt="" className="information__image" src={element.text} />
              {createSourceLink(articleData[index + 1]?.text)}
            </div>
          ) : null}
        </React.Fragment>
      );

      return [sponsoredContent, renderedElement].filter(Boolean);
    });
  } else if (featureName === "cut") {
    return (
      <React.Fragment>
        {articleData.map((element, index) =>
          element.tag === "p" ? (
            <p key={index} className="information__paragraph">
              {element.text}
            </p>
          ) : null
        )}
        {sponsorBox}
      </React.Fragment>
    );
  }
  //if featureName is list
  else if (featureName === "list") {
    return (
      <ul className="post__list">
        {listItems.map((element, index) => (
          <li key={index}>{element.text}</li>
        ))}
        {sponsorBox}
      </ul>
    );
  }
};

export default ArticleContent;
