import { useState, useEffect } from "react";
import React from "react";
import ArticleContent from "./articleContent.jsx";
import DonationSidebar from "./donationSidebar.jsx";
import DeleteBox from "./deleteBox.jsx";
import Overview from "./overview.jsx";
import Hcaptcha from "../components/hcaptcha.jsx";

const ArticleLayout = ({
  notFound,
  editId,
  slug,
  articleId,
  forbidden,
  articleData,
  featuredImageURL,
  mainPageData,
  PUBLIC_SITE_IP,
  PUBLIC_API_IP_PROXY,
  PUBLIC_CAPTCHA_SITE_KEY,
}) => {
  const [captchaToken, setCaptchaToken] = useState(false);
  const [deleteBox, setDeleteBox] = useState(editId);
  const [articleDataState, setArticleDataState] = useState(articleData);
  const [articleIdFromSession, setArticleIdFromSession] = useState();
  const [slugFromSession, setSlugFromSession] = useState();
  const [editIdFromSession, setEditIdFromSession] = useState();
  const [previousPage, setPreviousPage] = useState(`${PUBLIC_SITE_IP}`);

  //? gets article id, slug, editId from localStorage and deletes the localStorage
  useEffect(() => {
    //? only applies if you visit 429 page
    if (forbidden) {
      const articleInfoJSON = localStorage.getItem("articleInfo");
      if (articleInfoJSON) {
        const {
          articleId: id,
          slug: articleSlug,
          editId: edit,
        } = JSON.parse(articleInfoJSON);
        setArticleIdFromSession(id);
        setSlugFromSession(articleSlug);
        setEditIdFromSession(edit);
      }
    }
  }, []);

  //? sets the previousUrl so that user can manually click it when getting 429
  useEffect(() => {
    if (editIdFromSession) {
      setPreviousPage(
        `${PUBLIC_SITE_IP}${articleIdFromSession}/${slugFromSession}/${editIdFromSession}`
      );
    } else if (articleIdFromSession && slugFromSession) {
      setPreviousPage(
        `${PUBLIC_SITE_IP}${articleIdFromSession}/${slugFromSession}`
      );
    }
  }, [articleIdFromSession, slugFromSession, editIdFromSession]);

  const notFoundMessage = (
    <>
      <h2 className="information__header information__header--post">
        Ova stranica nije pronađena
      </h2>
      <p className="information__paragraph">
        Ova stranica nije pronađena. Moguće je da ste uneli nepravilnu adresu
        ili da je stranica obrisana.
      </p>
    </>
  );
  //? this one is to handle 429 ratelimit error
  const forbiddenPage = (
    <>
      <h2 className="information__header information__header--post">
        Ova stranica trenutno nije dostupna
      </h2>
      <p className="information__paragraph">
        Ova stranica nije trenutno dostupna. Servis je trenutno pod velikim
        opterećenjem i ne može da obradi vaš zahtev. Molimo vas sačekajte minut
        i probajte ponovo.
      </p>
      <p className="information__paragraph">
        Iskoristite ovaj link da probate da pristupite stranici ponovo:{" "}
        <strong>
          {/* Checking if setEditIdFromSession is defined before rendering the link */}
          <a href={previousPage}>{previousPage}</a>
        </strong>
      </p>
      <p className="information__paragraph">
        Ukoliko ne želite da čekate, rešite zagonetku klikom na{" "}
        <strong>Potvrdite da ste čovek </strong> polje.
      </p>
      <section className="websiteTitle__captcha">
        <Hcaptcha
          handleCaptchaToken={setCaptchaToken}
          articleId={articleIdFromSession}
          slug={slugFromSession}
          editId={editIdFromSession}
          forbidden={true}
          PUBLIC_CAPTCHA_SITE_KEY={PUBLIC_CAPTCHA_SITE_KEY}
          PUBLIC_SITE_IP={PUBLIC_SITE_IP}
        />
      </section>
    </>
  );

  //* WHEN ADDED, GENERATED URLS FOR DELETE AND EDIT
  // *FOR JUST ARTICLE

  return (
    <main className="content content--singlePage">
      {!forbidden ? <Overview articleData={articleDataState} /> : <Overview />}
      <article className="post">
        {/* if deleteBox is present, show the delete box  */}
        {/* deleteBox hold editId if you're on delete page  */}
        {deleteBox && (
          <DeleteBox
            editId={editId}
            slug={slug}
            articleId={articleId}
            PUBLIC_API_IP_PROXY={PUBLIC_API_IP_PROXY}
          />
        )}
        {forbidden && forbiddenPage}

        {notFound ? (
          notFoundMessage
        ) : !forbidden && articleDataState ? (
          <ArticleContent
            articleData={articleDataState}
            featuredImageURL={featuredImageURL}
          />
        ) : null}
      </article>
      <DonationSidebar mainPageData={mainPageData} />
    </main>
  );
};

export default ArticleLayout;
