import axios from "axios";

const DeleteBox = ({ editId, slug, articleId, PUBLIC_API_IP_PROXY }) => {
  const deleteArticle = async () => {
    try {
      const response = await axios.delete(
        PUBLIC_API_IP_PROXY + articleId + "/" + slug + "/" + editId + "/"
      );
      window.location.href = "/";
    } catch (error) {
      console.log("Error");
    }
  };

  //? returns to original article when clicking no
  const noDelete = () => {
    window.location.href = "/" + articleId + "/" + slug + "/";
  };

  return (
    <section className="post__deleteBox">
      <p className="deleteBox__paragraph">
        Da li želite da obrišete ovaj članak?
      </p>
      <button onClick={() => deleteArticle()} className="deleteBox__button">
        Da
      </button>
      <button onClick={() => noDelete()} className="deleteBox__button">
        Ne
      </button>
    </section>
  );
};

export default DeleteBox;
